<template>
        <div class="lg:hidden w-screen flex flex-col relative items-center justify-center my-8 md:my-16 lg:my-24">
          <div class="flex items-center flex-col px-5 md:px-0">
              <p class="md:text-sectionTitle text-xl w-full text-center font-understock">{{ $t('homepage.thisIsLa.title') }}</p>
              <p class="text-baselg w-full text-center font-blackMango uppercase tracking-wider">
                  {{ $t('homepage.thisIsLa.subtitle') }}</p>
          </div>
          <div class="m-4 rounded-md relative z-30" :class="activeIndex!=1 ? 'hidden' : ''">
              <img src="/img/thisisla/whoweare.jpg" class="h-full w-full object-cover rounded-md absolute">
              <div class="absolute overlay-cover h-full w-full rounded-md"></div>
              <div class="m-4">
                <div class="flex relative">
                  <button class="text-black font-semibold border border-white bg-white text-sm uppercase rounded-md px-2 py-1 tracking-widest" @click="setActiveIndex(1)">{{ $t('homepage.thisIsLa.who') }}</button>
                  <button class="text-white font-semibold border border-white text-sm uppercase rounded-md px-2 py-1 ml-1 tracking-widest" @click="setActiveIndex(2)">{{ $t('homepage.thisIsLa.services') }}</button>
                  <button class="text-white font-semibold border border-white text-sm uppercase rounded-md px-2 py-1 ml-1 tracking-widest" @click="setActiveIndex(3)">{{ $t('homepage.thisIsLa.founder') }}</button>
                </div>
                <div class="mt-4 inset-x-0 m-auto mt-6 items-center justify-center relative">
                    <p class="w-full uppercase font-blackMango text-lg font-bold text-white tracking-widest">{{$t('about.LAWhoWeAre.title')}}</p>
                </div>
                <div class="mt-2 inset-x-0 m-auto items-center justify-center relative">
                    <p class="w-full text-base font-normal text-white tracking-widest"  style="white-space: pre-line">{{$cutText(0,195,$processLanguage(information))}}</p>
                </div>
                <router-link :to="{ name: 'ThisIsLA', params: { lang: this.$i18n.locale}}">
                  <ButtonHomepage  bgColor="bronze" class="z-40 mt-6 w-full relative" :text="$t('buttons.discover')" />
                </router-link>
              </div>
          </div>
          <div class="m-4 rounded-md relative" :class="activeIndex!=2 ? 'hidden' : ''">
              <img src="/img/thisisla/services.jpg" class="h-full w-full object-cover rounded-md absolute">
              <div class="absolute overlay-cover h-full w-full rounded-md"></div>
              <div class="m-4">
                <div class="flex relative">
                  <button class="text-white font-semibold border border-white text-sm uppercase rounded-md px-2 py-1 tracking-widest" @click="setActiveIndex(1)">who</button>
                  <button class="text-black font-semibold border border-white bg-white text-sm uppercase rounded-md ml-1 px-2 py-1 tracking-widest" @click="setActiveIndex(2)">services</button>
                  <button class="text-white font-semibold border border-white text-sm uppercase rounded-md px-2 py-1 ml-1 tracking-widest" @click="setActiveIndex(3)">founder</button>
                </div>
                <div class="mt-4 inset-x-0 m-auto mt-6 items-center justify-center relative">
                    <p class="w-full uppercase font-blackMango text-lg font-bold text-white tracking-widest">{{$t('about.LAServices.title')}}</p>
                </div>
                <div class="mt-2 inset-x-0 m-auto items-center justify-center relative">
                    <p v-if="getComputedServiceDescription" class="w-full text-base font-normal text-white tracking-widest"  style="white-space: pre-line">{{$cutText(0,195,$processLanguage(getComputedServiceDescription))}}</p>
                </div>
                <router-link :to="{ name: 'ThisIsLA', params: { lang: this.$i18n.locale}}">
                  <ButtonHomepage  bgColor="bronze" class="z-40 mt-6 w-full relative" :text="$t('buttons.discover')" />
                </router-link>
              </div>
          </div>
          <div class="m-4 rounded-md relative" :class="activeIndex!=3 ? 'hidden' : ''">
              <img src="/img/thisisla/founder.png" class="h-full w-full object-cover object-top filter contrast-100 rounded-md absolute">
              <div class="absolute overlay-cover h-full w-full rounded-md"></div>
              <div class="m-4">
                <div class="flex relative">
                  <button class="text-white font-semibold border border-white text-sm uppercase rounded-md px-2 py-1 tracking-widest" @click="setActiveIndex(1)">who</button>
                  <button class="text-white font-semibold border border-white text-sm uppercase rounded-md px-2 py-1 ml-1 tracking-widest" @click="setActiveIndex(2)">services</button>
                  <button class="text-black font-semibold border border-white bg-white text-sm uppercase rounded-md ml-1 px-2 py-1 tracking-widest" @click="setActiveIndex(3)">founder</button>
                </div>
                <div class="mt-4 inset-x-0 m-auto mt-6 items-center justify-center relative">
                    <p class="w-full uppercase font-blackMango text-lg font-bold text-white tracking-widest">{{$t('about.LAWhoWeAre.title2')}}</p>
                </div>
                <div class="mt-2 inset-x-0 m-auto items-center justify-center relative">
                    <p class="w-full text-base font-normal text-white tracking-widest"  style="white-space: pre-line">{{getAgentDescription}}</p>
                </div>
                <router-link :to="{ name: 'ThisIsLA', params: { lang: this.$i18n.locale}}">
                  <ButtonHomepage  bgColor="bronze" class="z-40 mt-6 w-full relative" :text="$t('buttons.discover')" />
                </router-link>
              </div>
          </div>
          
        </div>
</template>
<style scoped>
.overlay-cover:after{
  border-radius:0.375rem;
}
</style>
<script>
import GeneralMixin from '@/models/general/GeneralModel';
import mixin from '@/models/agents/AgentsModel'
import { ServiceDescription } from '@/store/structs/services'
import ButtonHomepage from "@/components/buttons/ButtonHomepage.vue";
export default {
    name: "ThisIsLAMobile",
    mixins:[GeneralMixin, mixin],
    components:{
      ButtonHomepage,
    },
    data(){
        return{
            information: null,
            activeIndex: 1,
            founderDesc: null
        }
    },
    async created(){
        try {
            await this.getWhoWeAreDescription()
            this.information = this.$store.state.generalModule.who_we_are.description
            await this.getServiceDescription()
            await this.getAgents()
            await this.getMyAgent(this.$store.state.agentsModule.list[0].id)
            this.founderDesc = this.$store.state.agentsModule.agent;
        } catch (error) {
            console.log(error)
        }
    },
    computed:{
      getComputedServiceDescription(){
          const retrievedData = this.$store.state.generalModule?.service_description
            
          if(retrievedData){
            if(retrievedData.length == 0)
                return []
            else return new ServiceDescription(retrievedData[0])?.i18n?.description
          }
          else return []
          
      },
      getAgentDescription(){
          if(this.founderDesc == null) return null
          else return this.$cutText(0,195,this.$processLanguage(this.founderDesc.description))
        }
    },
    methods:{
      setActiveIndex(target){
          this.activeIndex = target;
      }
    }
}
</script>